import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledRed = styled.span`
  color: ${({ theme }) => theme.colors.fontRed};
`;

const RestaurantOpenTime = ({ openNow, openTime, closeTime }) => {
	const { t } = useTranslation();
	const openToday = !!openTime && !!closeTime;
	if (!openToday) {
		return <StyledRed>{t('restaurant.todayClosed')}</StyledRed>;
	}
	return (
		<>
			{openNow && (
				<>
					<span>
						{t('restaurant.open')}
						:
					</span>
					&nbsp;
					<span>
						{openTime.slice(0, 5)}
						{' '}
						-
						{closeTime.slice(0, 5)}
					</span>
				</>
			)}
			{!openNow && (
				<>
					<StyledRed>
						{t('restaurant.nowClosed')}
						:
					</StyledRed>
					&nbsp;
					<StyledRed>
						{openTime.slice(0, 5)}
						{' '}
						-
						{closeTime.slice(0, 5)}
					</StyledRed>
				</>
			)}
		</>
	);
};

export default RestaurantOpenTime;
