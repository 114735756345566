import * as moment from "moment";

const dayNumberToString = (dayNumber) => moment(new Date()).set("day", dayNumber).format("dddd").toUpperCase();

const todayClosed = {
	openNow: false,
	openTime: null,
	closeTime: null,
};

export const getBusinessHours = (businessHoursArray, day = null) => {
	if (!day) {
		day = moment(new Date()).get("day");
	}
	const stringDay = dayNumberToString(day);
	if (!businessHoursArray || !businessHoursArray.length) {
		return todayClosed;
	}
	const businessHours = businessHoursArray.find((hours) => stringDay === hours.day);
	if (!businessHours) {
		return todayClosed;
	}
	const currentTime = moment(new Date());
	const openTime = moment(businessHours.openTime, "HH:mm:ss");
	const closeTime = moment(businessHours.closeTime, "HH:mm:ss");
	const openNow = openTime.isBefore(currentTime) && currentTime.isBefore(closeTime);
	return {
		openNow,
		openTime: openTime.format("HH:mm"),
		closeTime: closeTime.format("HH:mm"),
	};
};
