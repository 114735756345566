import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import SEO from '../components/seo';
import 'react-rater/lib/react-rater.css';
import MarkerSVG from '../icons/map-marker.svg';
import { Loading } from '../components/common/loading';
import { StyledRestaurantName } from '../components/restaurants/restaurantListItem';
// import PDFViewer from '../components/common/PDFViewer';
import { clearLayoutAction, setLayoutAction } from '../state/layout.reducer';
import { AppContainer } from '../components/common/appContainer';

const StyledInfoWrapper = styled.div`
	background: #fff;
	left: 0;
	padding: 16px 0;
	width: 100%;
	z-index: 1006;
`;
const StyledAddress = styled.div`
	color: ${({ theme }) => theme.colors.fontGray};
	display: flex;
	font-size: 1rem;
	font-weight: 400;
	justify-content: space-between;
	line-height: 1.71rem;
	margin: 0;
`;

const StyledIconSvgWrapper = styled.div`
	text-align: right;
	width: 25px;

	svg,
	svg * {
		fill: ${({ theme }) => theme.colors.brandPurple};
		max-width: 12px;
	}
`;

const StyledImageWrapper = styled.div`
	width: 100%;
	img {
		width: 100%;
	}
`;

const MenuWrapper = styled.div`
	margin: 0 10px;
`;

const RestaurantCommentsPage = ({ pageContext }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const restaurantReducer = useSelector((state) => state.restaurant);
	const { entry } = pageContext;
	const data = {
		...entry,
		...restaurantReducer.data,
	};
	const menuImages = data?.images?.filter((image) => image.type === 'MENU');
	const { loading } = restaurantReducer;
	const menuNameString = `${data.name} - ${t('restaurant.menu')}`;

	useEffect(() => {
		dispatch(
			setLayoutAction({
				backTo: `/restaurant/${entry.slug}`,
				customLogoSrc: data?.images?.find((image) => image.type === 'LOGO')?.url || null,
				customLogoUrl: `/restaurant/${entry.slug}`,
			}),
		);

		return () => {
			dispatch(clearLayoutAction());
		};
	}, [entry, data]);

	const compareDate = (a, b) => {
		if (new Date(a).getTime() - new Date(b).getTime() > 0) return 1;
		return -1;
	};

	const sortedMenuImages = menuImages.sort(compareDate);
	return (
		<>
			<SEO title={menuNameString} />
			<AppContainer>
				<StyledInfoWrapper>
					<StyledRestaurantName>{data.name}</StyledRestaurantName>
					<StyledAddress>
						<span>{data?.localization?.name}</span>
						<StyledIconSvgWrapper
							as="a"
							href={`https://www.google.com/maps/search/?api=1&query=${data?.localization?.lat},${data?.localization?.lng}`}
							target="_blank"
						>
							<MarkerSVG />
						</StyledIconSvgWrapper>
					</StyledAddress>
				</StyledInfoWrapper>
				{loading && <Loading />}
				{!loading && (
					<MenuWrapper>
						<TransformWrapper
							defaultPositionX={0}
							defaultPositionY={0}
						>
							<TransformComponent>
								<StyledImageWrapper>
									{sortedMenuImages.map((image) => (
										<img
											key={image.url}
											alt="test"
											src={image.url}
										/>
									))}
								</StyledImageWrapper>
							</TransformComponent>
						</TransformWrapper>
					</MenuWrapper>
				)}
			</AppContainer>
		</>
	);
};

export default RestaurantCommentsPage;
